<template>
    <v-banner v-if="showPrompt" color="info" dark class="text-left">
        Get our free app. It won't take up space on your phone and also works offline!
        <template v-slot:actions>
            <v-btn text @click="dismissPrompt">Dismiss</v-btn>
            <v-btn text @click="installPWA">Install</v-btn>
        </template>
    </v-banner>
</template>

<script>
import { Workbox } from 'workbox-window';
export default {
    name: "pwa",
    data() {
        return {
            showPrompt: false,
            promptEvent: null
        }
    },
    created() {
        const workbox = new Workbox('~/service-worker.js');
        workbox.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
            this.promptEvent = event;
            this.showPrompt = true;
        });
    },
    methods: {
        showInstallPrompt() {
            if (!this.promptEvent) {
                return;
            }
            this.promptEvent.prompt();
            this.promptEvent = null;
            this.installEvent.userChoice.then((choice) => {
                this.dismissPrompt() // Hide the prompt once the user's clicked
                if (choice.outcome === 'accepted') {
                    // Do something additional if the user chose to install
                } else {
                    // Do something additional if the user declined
                }
            })
        },
        dismissPrompt() {
            this.showPrompt = false;
        },
    }
}
</script>

<style scoped>

</style>